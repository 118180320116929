@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Outfit:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

body {
  margin: 0;
  padding: 0;
  background-color: #f9fafb;
  font-family: "Inter", sans-serif !important;
}
.custom-calendar-class .ant-picker-panel-layout {
  padding: 0 14px !important;
}
.custom-calendar-class
  .ant-picker-range-wrapper.ant-picker-date-range-wrapper
  .ant-picker-panel-container {
  box-shadow: 0px 20px 24px -4px #10182814;
  border: 1px solid #f2f4f7 !important;
  border-radius: 8px !important;
}
.custom-calendar-class
  .ant-picker-range-wrapper.ant-picker-date-range-wrapper
  .ant-picker-panel-container
  .ant-picker-presets {
  width: 170px !important;
}
.custom-calendar-class
  .ant-picker-range-wrapper.ant-picker-date-range-wrapper
  .ant-picker-panel-container
  ul {
  padding-left: 0 !important;
  border-right: 1px solid #eaecf0 !important;
}
.custom-calendar-class
  .ant-picker-range-wrapper.ant-picker-date-range-wrapper
  .ant-picker-panel-container
  li {
  color: #344054 !important;
  font-size: 14px !important;
  font-family: "Inter" !important;
  font-weight: 500 !important;
  height: 40px !important;
  display: flex;
  align-items: center;
}
.custom-calendar-class .ant-picker-panels {
  padding-top: 12px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.custom-calendar-class .ant-picker-header {
  border-bottom: 0 !important;
}
.custom-calendar-class .ant-picker-month-btn {
  font-size: 16px !important;
  font-weight: 600;
  font-family: "Inter" !important;
}
.custom-calendar-class .ant-picker-content thead tr th {
  color: #344054 !important;
  font-family: "Inter" !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}
.custom-calendar-class .ant-picker-content td {
  padding: 0 !important;
}
.custom-calendar-class td.ant-picker-cell .ant-picker-cell-inner {
  color: #667085;
  font-family: "Inter" !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.yearPicker .ant-picker-content .ant-picker-cell-inner {
  color: #344054;
  font-family: "Inter" !important;
  font-size: 14px !important;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400 !important;
}
.custom-calendar-class
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-disabled
  ):before {
  background-color: #f9fafb !important;
}
.custom-calendar-class
  .ant-picker-cell.ant-picker-cell-range-start.ant-picker-cell-in-view
  .ant-picker-cell-inner {
  border-radius: 100px !important;
  background-color: #155eef !important;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-calendar-class
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  content: "." !important;
  border: 0 !important;
  color: #155eef;
  position: absolute;
  top: 10px;
  font-size: 40px !important;
}
.custom-calendar-class
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-disabled
  )
  .ant-picker-cell-inner {
  background-color: #155eef !important;
  border-radius: 100px !important;
}
.custom-calendar-class
  .ant-picker-cell-in-view.ant-picker-cell-in-range:not(
    .ant-picker-cell-disabled
  ):before {
  background-color: #f9fafb !important;
  height: 40px;
}
.custom-calendar-class .ant-picker-footer-extra .w-full.py-4 {
  display: flex !important;
  align-items: baseline !important;
  padding: 10px 0 0 !important;
}
.custom-calendar-class .ant-input::placeholder {
  color: #667085 !important;
}
.custom-calendar-class .ant-input {
  border: 1px solid #d0d5dd !important;
  color: #101828;
  font-size: 16px !important;
  font-weight: 400 !important;
  height: 44px !important;
  font-family: "Inter" !important;
  width: 136px;
  margin-right: 0;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px #1018280d;
}
button.ant-picker-year-btn {
  font-family: "Inter" !important;
  font-size: 16px !important;
}
span.sepeartor {
  display: inline-flex;
  color: #667085;
  margin: 0 8px;
  font-size: 31px;
  position: relative;
  top: 3px;
}
.custom-calendar-class .ant-picker-footer-extra .ant-btn-default {
  box-shadow: 0px 1px 2px 0px #1018280d;
  border: 1px solid #d0d5dd;
  height: 40px !important;
  border-radius: 8px !important;
  font-family: "Inter" !important;
  font-size: 14px !important;
  color: #344054 !important;
  font-weight: 600;
}
.newCards {
  box-shadow: 0px 2px 4px -2px #1018280f;
  box-shadow: 0px 4px 8px -2px #1018281a !important;
  border-radius: 12px !important;
}
.newCards button {
  position: relative;
  top: 8px;
}
.newCards input {
  border: 1px solid #d0d5dd;
  border-radius: 8px !important;
  color: #667085 !important;
  box-shadow: 0px 1px 2px 0px #1018280d !important;
}
.newCards label {
  font-size: 14px;
  color: #344054;
  font-weight: 500;
  font-family: "Inter";
  display: inline-block;
  padding-bottom: 6px;
}
body .mt-3.flex.items-start .checkedBox {
  height: auto !important;
  position: relative;
  top: 4px;
}
.mewForm p {
  font-size: 16px !important;
  color: #475467 !important;
  font-weight: 400 !important;
}
.mewForm h3 {
  font-size: 30px;
  color: #101828;
  padding-bottom: 20px;
  font-family: "Inter";
}
body .phoneNumber .ant-input {
  border: 0 !important;
  color: #000 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  font-family: "Inter" !important;
  box-shadow: none !important;
  height: auto !important;
}
.phoneNumber .ant-select-selection-item {
  background: transparent !important;
  /* width: 47px !important;
  max-width: 47px !important; */
  padding: 0 !important;
}

.phoneNumber .ant-select-arrow {
  /* position: relative; */
  left: 31px !important;
}
.phoneNumber .ant-select-selector {
  padding: 0 !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  padding-left: 4px !important;
  color: #667085 !important;
  text-align: left !important;
  background: #efefef !important;
  height: 24px !important;
  top: 4px;
}
.phoneNumber .ant-select {
  width: 47px !important;
}

.phoneNumber .ant-input-group-wrapper {
  border: 1px solid #d0d5dd;
  padding: 5px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 1px 2px 0px #1018280d;
}

.phoneNumber .ant-input-group-addon {
  width: 56px !important;
  max-width: 58px !important;
  background-color: transparent !important;
  padding: 0 !important;
  height: 24px !important;
  border: 0 !important;
}
.headSignup {
  font-size: 36px !important;
  font-weight: 600 !important;
  color: #101828 !important;
  padding-bottom: 10px !important;
}

.selectInput div {
  border-radius: 8px;
  color: #101828;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  height: auto;
  min-height: 32px;
  overflow: hidden;
  padding-top: 2px;
}
.custom-select__value-container {
  height: 30px !important;
  min-height: 30px !important;
  position: relative;
  bottom: 5px;
}
.custom-select__control {
  height: 44px !important;
  min-height: 44px !important;
}
.selectInput {
  height: 44px !important;
  min-height: 44px !important;
}
.signupForms h5 {
  color: #344054 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-top: 8px !important;
}
.headText {
  font-size: 16px !important;
  color: #667085 !important;
  font-weight: 400 !important;
}
.apexcharts-legend-series .apexcharts-legend-marker {
  width: 8px !important;
  height: 8px !important;
  position: relative !important;
  transform: translate(0px, -2px) !important;
}
.tabsSetting {
  width: 100% !important;
  justify-content: space-between !important;
}
.custom-calendar-class .ant-picker-footer-extra .ant-btn-primary {
  box-shadow: 0px 1px 2px 0px #1018280d;
  border: 1px solid #d0d5dd;
  height: 40px !important;
  font-family: "Inter" !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  color: #fff !important;
  font-weight: 600;
  background-color: #155eef !important;
}
.custom-calendar-class .ant-picker-month-btn {
  font-size: 16px !important;
  font-weight: 600;
  font-family: "Inter" !important;
}
.borderss span {
  font-family: "Inter" !important;
  font-size: 14px !important;
  white-space: nowrap !important;
}

.bg-whites {
  font-family: "Inter";
  font-size: 14px;
  background: #fff;
  color: #344054 !important;
  border-left: 0 !important;
  font-weight: 600 !important;
  height: 40px !important;
}
.dashboardCard h6 {
  font-size: 30px !important;
  font-family: "Inter" !important;
  font-weight: 600 !important;
}

.dashboardCard h5 {
  font-size: 14px !important;
  color: #475467 !important;
  font-family: "Inter" !important;
  padding-bottom: 4px;
  padding-top: 4px;
}
.commisionSearch {
  box-shadow: 0px 1px 2px 0px #1018280d !important;
  border: 1px solid #d0d5dd !important;
  border-radius: 8px !important;
}
.commisionSearch input {
  color: #000 !important;
  font-family: "Inter" !important;
}
.commisionSearch input::placeholder {
  color: #667085 !important;
}
.commisionTable {
  box-shadow: 0px 1px 2px 0px #1018280f;
  box-shadow: 0px 1px 3px 0px #1018281a !important;
  border: 1px solid #eaecf0 !important;
  border-radius: 12px !important;
}
.commisionTable table thead tr th:nth-child(1) img {
  opacity: 0 !important;
}
.commisionTable table thead tr th:nth-child(3) img {
  opacity: 0 !important;
}
.commisionTable table thead tr th:nth-child(4) img {
  opacity: 0 !important;
}
.commisionTable table thead tr th:nth-child(5) img {
  opacity: 0 !important;
}
.commisionTable table thead tr th:nth-child(6) img {
  opacity: 0 !important;
}
.commisionTable table tbody td {
  padding: 24px 17px !important;
  font-family: "Inter" !important;
}
.commisionTable table tbody td:nth-child(1) {
  color: #475467 !important;
  font-size: 14px !important;
  font-family: "Inter" !important;
  font-weight: 400 !important;
}
.commisionTable table tbody td:nth-child(2) {
  color: #101828 !important;
  font-size: 14px !important;
  font-family: "Inter" !important;
  font-weight: 500 !important;
}
.commisionTable table tbody td:nth-child(3) {
  color: #101828 !important;
  font-size: 14px !important;
  font-family: "Inter" !important;
  font-weight: 500 !important;
}
.commisionTable table tbody td:nth-child(4) {
  color: #101828 !important;
  font-size: 14px !important;
  font-family: "Inter" !important;
  font-weight: 500 !important;
}
.commisionTable table tbody td:nth-child(5) {
  color: #101828 !important;
  font-size: 14px !important;
  font-family: "Inter" !important;
  font-weight: 500 !important;
}
.commisionTable table tbody td:nth-child(6) {
  color: #101828 !important;
  font-size: 14px !important;
  font-family: "Inter" !important;
  font-weight: 500 !important;
}
.firstForm {
  padding: 20px 20px 0;
}
.rightSides h6 {
  font-family: "Inter" !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #344054 !important;
}
.secondForm {
  padding: 0px 20px 20px;
}
.thirdForm {
  padding: 0 20px !important;
}
.profileSection {
  width: 64px !important;
  height: 64px !important;
  min-width: 64px !important;
}
.pageProfile {
  border-top: 1px solid #eaecf0 !important;
  height: 73px !important;
  padding-right: 20px !important;
}
.editBtn {
  width: 80px !important;
  height: 40px !important;
  font-family: "Inter" !important;
  padding: 0 !important;
  color: #344054 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  border: 1px solid #d0d5dd !important;
  border-radius: 8px !important;
}
.mainPassword .ant-input-affix-wrapper {
  padding: 0 15px 0 30px !important;
  border: 1px solid #d0d5dd !important;
  border-radius: 8px !important;
  box-shadow: 0px 1px 2px 0px #1018280d;
}
.secondForm img {
  position: absolute;
  z-index: 9;
  top: 40px;
  left: 27px;
}
.dateYear {
  box-shadow: 0px 1px 2px 0px #1018280d;
  font-family: "Inter";
  color: #667085 !important;
  border: 1px solid #d0d5dd !important;
  height: 40px !important;
}
.dateYear .ant-picker-input {
  flex-flow: row-reverse !important;
}
.dateYear input {
  font-weight: 600 !important;
  color: #667085 !important;
}
.ant-picker-panel-container.ant-picker-year-panel-container {
  box-shadow: 0px 8px 8px -4px #10182808;
  box-shadow: 0px 20px 24px -4px #10182814;
  border: 1px solid #f2f4f7;
  width: auto;
  border-radius: 8px;
}
.ant-picker-panel-layout {
  justify-content: center;
  padding: 15px 15px 8px !important;
}
.ant-picker-year-panel {
  width: 320px !important;
}
.ant-picker-year-panel .ant-picker-header {
  border: 0 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  font-family: "Inter" !important;
}
td.ant-picker-cell.ant-picker-cell-disabled {
  font-size: 14px;
  color: #667085;
  opacity: 0.3;
  font-weight: 400;
  font-family: "Inter";
}
.ant-picker-dropdown .ant-picker-cell-disabled::before {
  background-color: transparent !important;
}
.ant-picker-super-prev-icon {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAMCAYAAABfnvydAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACESURBVHgBfZA9DkBAEIXfrp/aERzBFdyEQq/XaESrlCgcxRE4giPoJZjdLIk16zU7s9/Le8kIMMrKJgmB8QRyn4MBMBGEh32THDRr2nf1Klxw6KpFDeIPakNWtnGAY+agkiQYPVGM9H9BFfRMajntintwmV7JnOlTbZukbTDRKeFVXfICA/87B36br4kAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  width: 12px !important;
  background-size: contain;
  height: 12px !important;
  transform: rotate(360deg) !important;
}
td.ant-picker-cell.ant-picker-cell-hover.ant-picker-cell-selected.ant-picker-cell-in-view
  .ant-picker-cell-inner {
  background: transparent !important;
  color: #155eef !important;
  font-weight: 400;
}
.ant-picker-super-prev-icon::after {
  display: none !important;
}
.ant-picker-super-prev-icon::before {
  display: none !important;
}
.ant-picker-super-next-icon::after {
  display: none !important;
}
.ant-picker-super-next-icon {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAMCAYAAABfnvydAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACMSURBVHgBdZAxDkBAEEX/EGpHcARXcBMKvV6jEa1S4hCO4AgcwRH0EszISuxkd4rdnbw3s5Ohou7TGNd8A+U0NBtUBBGuhGFKwFLVXaYFksOARRKW838n+h4+if7tXBLpP7UUaOE0N0OEOA9LKLg64mqT5uPQ7uSD1pA++AqySV7W6oIS7yYJ9+6CEg802z+7tgYOLgAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  width: 12px !important;
  background-size: contain;
  height: 12px !important;
  transform: rotate(360deg) !important;
}
td.ant-picker-cell.ant-picker-cell-in-view {
  font-family: "Inter" !important;
}
span.ant-picker-super-next-icon::before {
  display: none !important;
}
.dateYear .anticon.anticon-calendar {
  display: none !important;
}
.dateYear .ant-picker-suffix {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAUCAYAAACAl21KAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAF1SURBVHgB7VNLTsMwFJwkBrGDG9Q9QfkJITYYLkCgsKacgPYInID2BO0ekHKDmh2LVuoNWk5A1jSJGbcNCiFAg1gykuPEfhnPe34DFGBf+RJfYFP5G0XrblFgBDHeVefN/N62umgIiJeD47NKfs/JEEgXwndgpAPnmkua4zEXf8ihDEzHwJkkiIKRDibvRHuqfpnA6eF3aA30fduxSih3bE8AkhbVhNzs21NXELezf0zhNa1a7p3aOM5dzixFVBUkkXNppjPQD4GtEddGNrWnhewUO6quGVmJEWmmFLJmN/yv68FTgimxNgYJEqsENoDTVlEOQx7EKcivUxkPX8CFd8KbkiiHWvoiMrw+Hz5KweATUcLqr/I6UQKvbBc24u0HIlKF+eL+BBY7TFW5+CP8E30PaxeX1z6aM7oSJUFH1FKimfvpoTE/JI3bo3eel+RZ52hasw/1XXXWRzHiIxqvT9YGykHHmF7NVWVgnb8GbCzLkm3gN8ymf+Lq3y9pAAAAAElFTkSuQmCC);
  width: 18px;
  height: 20px;
  margin: 0 5px 0 0 !important;
  background-position: 0;
  background-repeat: no-repeat;
  background-size: contain;
}
.passwords img {
  position: absolute;
  z-index: 9;
  top: 40px;
  left: 27px;
}
.firstForm img {
  position: absolute;
  z-index: 9;
  top: 60px;
  left: 27px;
}
body .mainPassword .ant-input-affix-wrapper input::placeholder {
  color: #667085 !important;
}
.passwords {
  padding: 0 22px !important;
}
body .mainPassword .ant-input-affix-wrapper input {
  color: #101828 !important;
  border: 0 !important;
}
.deleteBtn {
  background: #155eef !important;
  width: 109px !important;
  height: 40px !important;
  border: 1px solid #155eef !important;
  font-family: "Inter" !important;
  font-size: 14px !important;
  font-weight: 600;
  border-radius: 8px !important;
}
.uploadingFile {
  border: 1px solid #eaecf0 !important;
  border-radius: 12px !important;
  font-family: "Inter" !important;
}
.textuals {
  font-size: 14px !important;
  color: #475467 !important;
}
.rightSides .ant-input.ant-input-disabled {
  color: #667085 !important;
}
.rightSides input {
  box-shadow: 0px 1px 2px 0px #1018280d !important;
  border: 1px solid #d0d5dd !important;
  border-radius: 8px !important;
  font-family: "Inter";
  color: #101828;
}
.rightSides {
  box-shadow: 0px 1px 2px 0px #1018280f;
  box-shadow: 0px 1px 3px 0px #1018281a !important;
  border: 1px solid #eaecf0 !important;
  border-radius: 12px !important;
  padding: 0 !important;
}
.commisionTable table tbody td:nth-child(7) span {
  font-size: 14px !important;
  font-family: "Inter" !important;
  font-weight: 500 !important;
}
.commisionTable table thead tr th {
  background: #f9fafb !important;
  color: #475467 !important;
  font-size: 12px !important;
  font-family: "Inter" !important;
  font-weight: 500 !important;
  padding: 12px 16px !important;
}
.dashboardCard {
  border: 1px solid #eaecf0 !important;
  height: 114px !important;
  box-shadow: 0px 1px 2px 0px #1018280f;
  box-shadow: 0px 1px 3px 0px #1018281a !important;
  border-radius: 12px !important;
  padding: 20px 20px !important;
}
.bg-transparents {
  background: #f9fafb !important;
  font-family: "Inter" !important;
  font-size: 14px !important;
  height: 40px !important;
  color: #1d2939 !important;
  font-weight: 600 !important;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}
.tablesBox {
  box-shadow: 0px 1px 2px 0px #1018280f;
  box-shadow: 0px 1px 3px 0px #1018281a !important;
  border: 1px solid #eaecf0 !important;
  border-radius: 12px !important;
}

.tablesBox .ant-table-wrapper thead tr th {
  background: #f9fafb !important;
  padding-top: 10px;
  padding-bottom: 11px;
  font-family: "Inter" !important;
  color: #475467 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

.tablesBox table tbody tr td {
  padding: 25px 18px !important;
  font-size: 14px !important;
  font-family: "Inter" !important;
}

.tablesBox table tbody tr td:nth-child(1) {
  color: #101828 !important;
  font-family: "Inter" !important;
  font-weight: 500 !important;
}

.tablesBox table tbody tr td:nth-child(2) {
  color: #475467 !important;
  font-family: "Inter" !important;
  font-weight: 400 !important;
}

.tablesBox table tbody tr td:nth-child(4) {
  color: #475467 !important;
  font-family: "Inter" !important;
  font-weight: 400 !important;
}

.tablesBox table tbody tr td:nth-child(5) {
  color: #475467 !important;
  font-family: "Inter" !important;
  font-weight: 600 !important;
}
.tablesBox table tbody tr td:nth-child(3) span {
  font-size: 12px !important;
  font-family: "Inter" !important;
  font-weight: 500 !important;
}
.tablesBox table tbody tr td {
  border-bottom: 1px solid #eaecf0 !important;
}
.paginationButton button {
  font-family: "Inter" !important;
  color: #344054 !important;
  font-weight: 600 !important;
  box-shadow: 0px 1px 2px 0px #1018280d !important;
  border: 1px solid #d0d5dd !important;
  border-radius: 8px !important;
}
/* .tablesBox .ant-table-wrapper thead tr th img {
  opacity: 0 !important;
} */
.signupForm h5 {
  font-size: 14px !important;
  font-family: "Inter";
  color: #344054 !important;
}
.signupForm input {
  border: 1px solid #d0d5dd;
  height: 44px !important;
  border-radius: 8px !important;
  box-shadow: 0px 1px 2px 0px #1018280d;
}
.signBtn {
  height: 44px !important;
  background: #155eef !important;
  border: 1px solid #155eef !important;
  font-size: 16px !important;
  font-family: "Inter" !important;
  font-weight: 600 !important;
}
.boxforgot label {
  color: #344054;
  font-weight: 500;
  font-family: "Inter";
  font-size: 14px;
  padding-bottom: 6px;
  display: block;
}
.boxforgot input {
  box-shadow: 0px 1px 2px 0px #1018280d !important;
  border: 1px solid #d0d5dd !important;
  border-radius: 8px !important;
}
.boxforgot input::placeholder {
  color: #667085 !important;
  font-weight: 400 !important;
}
.reste h1 {
  color: #101828 !important;
  font-size: 30px !important;
  padding: 11px 8px 14px !important;
}
.reste p {
  color: #475467 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}
.reste p span {
  font-weight: 500 !important;
}
.boxforgot {
  width: 440px !important;
  margin: 30px auto !important;
  box-shadow: 0px 2px 4px -2px #1018280f;
  box-shadow: 0px 4px 8px -2px #1018281a !important;
  padding: 34px !important;
}
.forgotText {
  font-family: "Inter" !important;
  font-size: 30px !important;
  font-weight: 600 !important;
  padding-bottom: 20px !important;
  margin-bottom: 0 !important;
}
.resets {
  color: #475467 !important;
  font-family: "Inter" !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}
.text-left.passwords {
  font-family: "Inter" !important;
  margin: 26px 0 0 !important;
  text-align: center !important;
}
.signupForm input {
  font-weight: 400 !important;
  color: #000;
  text-transform: none !important;
  outline: 0 !important;
}
.signinForm h1 {
  font-size: 36px !important;
  font-family: "Inter" !important;
  color: #101828 !important;
}
.signupForm input::placeholder {
  color: #667085;
  font-family: "Inter", sans-serif !important;
}
.signupForm input {
  color: #101828;
  text-transform: none !important;
  outline: 0 !important;
}
input {
  font-family: "Inter" !important;
}
.ant-tooltip {
  font-family: "Inter" !important;
}
.ant-tooltip-inner {
  font-size: 12px !important;
}
.zoom-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 2px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  background: #3f51b5;
  width: 100%;
}

.zoom-range::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #fff;
  background: #fff;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.zoom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #fff;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.controls:hover input[type="range"]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  border-radius: 50%;
}
.controls {
  z-index: 9999999;
  position: absolute;
  bottom: 14px;
  width: 96%;
  left: 8px;
}
.controls:hover input[type="range"]::-moz-range-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
}
.adminHeader {
  position: fixed;
  width: 100%;
  z-index: 99;
}

h1 {
  font-family: "Inter", sans-serif;
}

h2 {
  font-family: "Inter", sans-serif !important;
}

h3 {
  font-family: "Inter", sans-serif !important;
}

h4 {
  font-family: "Inter", sans-serif !important;
}

h5 {
  font-family: "Inter", sans-serif !important;
}

h6 {
  font-family: "Inter", sans-serif !important;
}

p {
  font-family: "Inter", sans-serif !important;
}

.dateRangePicker.yearWise {
  position: relative;
}

.dateRangePicker.yearWise img {
  position: absolute;
  top: 11px !important;
  left: 8px !important;
}

.inputSearch input::placeholder {
  opacity: 1 !important;
  color: #667085 !important;
  font-family: "Inter" !important;
  font-size: 16px !important;
}

.tableBoxed table thead th {
  background: #f9fafb !important;
  padding: 10px 17px !important;
  color: #475467 !important;
  font-size: 13px !important;
  font-weight: 500 !important;
}

.tableBoxed table tbody tr td:nth-child(1) {
  color: #101828 !important;
  font-family: "Inter" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  padding: 20px 18px !important;
}

.tableBoxed table tbody tr td:nth-child(2) {
  color: #475467 !important;
  font-family: "Inter" !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  padding: 20px 18px !important;
}

.tableBoxed table tbody tr td:nth-child(4) {
  color: #475467 !important;
  font-family: "Inter" !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  padding: 20px 18px !important;
}

.tabsBtn button span {
  font-size: 14px !important;
  color: #1d2939 !important;
  font-family: "Inter" !important;
}

.meetingBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  top: 34px;
}

.meetingBox h3 {
  font-size: 18px;
  font-weight: 500;
  font-family: "Inter";
}

.leftForm label {
  color: #344054;
  font-size: 14px;
  font-family: "Inter";
  font-weight: 500;
  padding-bottom: 8px;
  display: block;
}
.leftForm input::placeholder {
  color: #667085;
  font-family: "Inter";
}
.leftForm textarea::placeholder {
  color: #667085;
  font-family: "Inter";
}
.container.active .ant-col.ant-col-16 {
  display: none !important;
}
.container.active .ant-col.ant-col-8 {
  flex: 0 0 99.333333% !important;
  max-width: 99.33333333333333% !important;
}
.iframe iframe {
  width: 100% !important;
  height: 700px;
}
.leftForm textarea {
  color: #667085;
  font-family: "Inter";
}
.leftForm input {
  font-family: "Inter";
  box-shadow: 0px 1px 2px 0px #1018280d;
  border: 1px solid #d0d5dd !important;
  padding: 8px;
  border-radius: 8px !important;
}

.btnSubmit {
  height: 48px;
  width: 95%;
  background: #155eef !important;
  border-color: #155eef !important;
  color: #fff !important;
  font-size: 16px !important;
  font-family: "Inter";
  font-weight: 600;
  border-radius: 8px !important;
}

.boxedContact.active {
  border: 1px solid #84adff !important;
}

.boxedContact {
  cursor: pointer;
  width: 230px;
  height: 163px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px 0px #1018280d;
  border-radius: 8px;
  background: #fff;
  padding: 25px 20px;
}

.tabsBtn button {
  height: 40px !important;
}

.tableBoxed table tbody tr td:nth-child(5) {
  color: #475467 !important;
  font-family: "Inter" !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  padding: 20px 18px !important;
}

.inputSearch img {
  position: relative;
  bottom: 1px;
}

input.rmdp-input {
  width: 150px !important;
  height: 40px !important;
  box-shadow: 0px 1px 2px 0px #1018280d !important;
  border: 1px solid #d0d5dd !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #344054 !important;
  padding-left: 36px;
}

.dateBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.labelCard {
  font-size: 14px;
  color: #475467;
  font-family: "Inter";
}

.copyBtn input {
  border: 1px solid #d0d5dd !important;
  color: #667085;
  font-weight: 700 !important;
  font-family: "Inter", sans-serif !important;
  height: 40px;
}
.copyBtn button {
  height: 40px;
  border-left: 0 !important;
  display: flex;
  align-items: center;
  color: #344054;
  font-size: 14px !important;
}
.cardss .grid {
  box-shadow: 0px 1px 2px 0px #1018280f;
  box-shadow: 0px 1px 3px 0px #1018281a !important;
  height: 114px;
  border: 1px solid #eaecf0;
  border-radius: 12px !important;
}
.searchBoxed .ant-input-affix-wrapper {
  box-shadow: 0px 1px 2px 0px #1018280d !important;
  width: 320px !important;
  border: 1px solid #d0d5dd !important;
}
.searchBoxed .ant-input-affix-wrapper input {
  color: #000 !important;
  font-family: "Inter" !important;
  font-size: 16px !important;
}
.searchBoxed .ant-input-affix-wrapper input::placeholder {
  font-size: 16px !important;
  color: #667085 !important;
}
span {
  font-family: "Inter", sans-serif !important;
}

.headingText {
  font-weight: 700 !important;
}

.logoImg img {
  margin: 0 auto !important;
}

.phoneNumber .ant-input-group-wrapper {
  border: 1px solid #d0d5dd;
  padding: 5px;
  border-radius: 8px;
  background: #fff;
}

.phoneNumber .ant-input-group-addon {
  width: 56px !important;
  max-width: 58px !important;
  background-color: #efefef !important;
  padding: 0 !important;
  height: 24px !important;
  border: 0 !important;
}

.bg_img {
  background-image: url(../src/assets/images/login.png);
  height: 100%;
  position: fixed;
  width: 50%;
  background-size: 100%;
}

.apexcharts-legend.apexcharts-align-center.apx-legend-position-right {
  top: 20px !important;
}

.apexcharts-legend-marker {
  border-radius: 100px !important;
  position: relative;
  top: 2px !important;
  margin-right: 6px !important;
}

.apexcharts-yaxis {
  display: none !important;
}

.custom-steps .ant-steps-item-content {
  background: transparent !important;
}

.navbar {
  background-color: white;
  padding-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.custom-drawer .ant-drawer-header {
  display: flex;
  justify-content: space-between;
  /* Align items to opposite ends */
}

.custom-drawer .ant-drawer-title {
  order: 2;
  /* Move the title to the right */
}

.custom-modal .ant-modal-content {
  padding: 0;
}

.ant-dropdown {
  box-shadow: none;
  padding: 0;
}

.ant-dropdown-menu {
  border: 1px solid #eaecf0 !important;
  border-radius: 8px !important;
  padding: 0 !important;
  box-shadow:
    0px 4px 6px -2px #10182808,
    0px 12px 16px -4px #10182814 !important;
  width: auto;
  min-width: 240px;
}

.ant-dropdown-menu-item {
  padding: 0 !important;
}

.ant-dropdown-menu-item:hover {
  background-color: red;
}
